import React from 'react'
import {FocusStyleManager} from '@blueprintjs/core'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './src/styles/global.css'
// import SessionContainer from './src/components/SessionContainer'


FocusStyleManager.onlyShowFocusOnTabs()


// export const wrapPageElement = function ({element, props}) {
//   return <SessionContainer {...props}>{element}</SessionContainer>
// }
